import {
	QUERY_COLLECTING_SESSION_ALL_KEY,
	QUERY_COLLECTING_SESSION_ALL_ZONES_KEY,
	QUERY_COLLECTING_SESSION_CREATE_KEY,
	QUERY_COLLECTING_SESSION_PUT_KEY,
	QUERY_COLLECTING_SESSION_WITHOUT_REFETCH_ALL_KEY
} from '@/const/collectingSession.const'
import collectingSessionService from '@/services/collectingSession.service'
import {
	IDtoPostCollectingSession,
	ICollectingSessionParams,
	IDtoPutCollectingSession
} from '@/types/collectingSession.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetCollectingSessionAllQuery = (
	params?: ICollectingSessionParams
) =>
	useQuery({
		queryKey: [
			QUERY_COLLECTING_SESSION_ALL_KEY,
			params?.page,
			params?.wh_zone_id
		],
		queryFn: async () =>
			await collectingSessionService.getAll(params).then(res => res.data)
	})
export const useGetCollectingSessionQuery = (
	params?: ICollectingSessionParams
) =>
	useQuery({
		queryKey: [QUERY_COLLECTING_SESSION_ALL_KEY, params?.wh_zone_id],
		retry: 0,
		queryFn: async () =>
			await collectingSessionService.getById(params).then(res => res.data),
		refetchInterval: 2000
	})
	
	
export const useGetCollectingSessionWithoutRefretchQuery = (
	params?: ICollectingSessionParams
) =>
	useQuery({
		queryKey: [QUERY_COLLECTING_SESSION_WITHOUT_REFETCH_ALL_KEY, params?.wh_zone_id],
		retry: 0,
		queryFn: async () =>
			await collectingSessionService.getById(params).then(res => res.data),
	})

export const useGetCollectingSessionAllZonesQuery = (
	params?: ICollectingSessionParams
) =>
	useQuery({
		queryKey: [QUERY_COLLECTING_SESSION_ALL_ZONES_KEY, params?.wh_zone_id],
		retry: 0,
		refetchInterval: 2000,
		queryFn: async () =>
			await collectingSessionService.getAllZones(params).then(res => res.data)
	})

export const usePostCollectingQuery = () =>
	useMutation({
		mutationKey: [QUERY_COLLECTING_SESSION_CREATE_KEY],
		mutationFn: async (data: IDtoPostCollectingSession) =>
			await collectingSessionService.create(data).then(res => res.data)
	})

export const usePutCollectingSessionQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_COLLECTING_SESSION_PUT_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({})
		},
		mutationFn: async (data: IDtoPutCollectingSession) =>
			await collectingSessionService.update(data).then(res => res.data)
	})
}
